import {
  COMMUNITY_USER_MEETING_REQUEST_FRAGMENT,
} from '@/graphql/_Fragments/CommunityUser/MeetingRequest';

export const MEETING_REQUEST_FULL_FRAGMENT = `
fragment meetingRequestFullFragment on MeetingRequest {
    uid
    schemaCode
    deleted
    createdTime
    createdTimestamp
    startTime
    endTime
    startTimestamp
    endTimestamp
    timezoneName
    readState
    trash
    message
    exhibitor {
        uid
        name
        logoFileResource {
            schemaCode
            path
        }
    }
    user {
        ...communityUserMeetingRequestFragment
    }
    guests {
        uid
        firstName
        lastName
        employerName
        jobTitle
        pictureFileResource {
            uid
            schemaCode
            path
        }
    }
    companyRep {
        uid
        firstName
        lastName
        employerName
        jobTitle
        pictureFileResource {
            uid
            schemaCode
            path
        }
    }
    meeting {
        uid
        schemaCode
        isCancelled
        subject
        description
        location
        startTime
        endTime
        startTimestamp
        endTimestamp
        timeZoneName
        creatorUser {
            uid
            firstName
            lastName
            pictureFileResource {
                uid
                schemaCode
                path
            }
        }
        participants {
            uid
            state
            user {
                uid
                firstName
                lastName
                pictureFileResource {
                    uid
                    schemaCode
                    path
                }
            }
        }
    }
    _commentCount
}
${COMMUNITY_USER_MEETING_REQUEST_FRAGMENT}`;
